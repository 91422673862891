<template>
<q-card
  v-if="loaded"
  :id="`general_history_${value.id}`"
  class="q-ma-md"
  :class="{ changed: changed, locked: locked }"
  v-observe-visibility="{
    callback: visibilityChanged,
    intersection: {
      rootMargin: '10000px 0px 10000px 0px'
    }
  }"
  >
  <q-card-section v-if="visible">
    <div class="row">
  <div class="col-xs-12 col-sm-12">
  <string-field
    v-model="value.notes"
    :prevValue="prevValue.notes"
    :disable="locked"
    label="Note"
    name="notes"
    subtype="textarea" />
</div>

</div>

  </q-card-section>
  <q-separator />
  <q-card-actions
    v-if="updatesCards"
    class="print-hide"
  >
    

    <q-btn
      label="Salva"
      color="brand"
      @click="submit"
      icon="save"
      :disable="!changed"
      />
  </q-card-actions>
</q-card>
</template>
<script>
/* Questo componente e' stato generato da:
"# rails generate create_edit_component Dentisti::GeneralHistory"
A partire da data_attributes di Dentisti::GeneralHistory;
Se lo modificate potra' anche funzionare, ma smettera' quando il componente
verra' rigenerato. Fare riferimento al wiki su github */
import EditFields from 'edit_fields';
import NutriwarnMethods from 'nutriwarn_methods';
import SubmitHistory from 'submit_history';
import ChannelSubscriptionMixin from 'channel_subscription_mixin';
import { mapGetters } from 'vuex';
export default {
  mixins: [EditFields,
           NutriwarnMethods,
           SubmitHistory,
           ChannelSubscriptionMixin],
  props: {
    patientId: {
      type: String,
      required: true
    },
  },
  data: function() {
    return {
      loaded: false,
      changed: false,
      locked: false,
      
      isVisit: false,
      visible: true,
      listUrl: `/patients/${this.patientId}/history.json?history_class=Dentisti::GeneralHistory`,
      errors: {},
        value: {
  "notes": null
},
        prevValue: {}
    };
  },
  watch: {
    value: {
      handler: function(newV, oldV) {
        this.changed = true;
      },
      deep: true
    }
  },
  methods: {
    visibilityChanged: function(isVisible, entry) {
      this.visible = isVisible;
    },
    toggleLock: function() {
      this.locked = !this.locked;
      if (!this.locked) alert("Ora puoi modificare questa scheda");
    },
    // Makes sense to expose a method to open a Group, that is,
    // a QExpansionItem; I use it to open search results;
    // I still want to open already populated Groups after data loaded,
    // but still no luck
    openGroup: function(gname) {
      // It works also via refs.. but then model is disaligned!
      // const comp = this.$refs[gname];
      // console.log("found? %o", comp);
      // comp.show();
      return this[gname] = true;
    },
    gotoGroup: function(gname) {
      const comp = this.$refs[gname]
      if (comp) {
        comp.$el.scrollIntoView();
        return true;
      } else {
        console.log("group %o not found - not going", gname);
        return false;
      }
    },
    // Give it time to repaint before going
    openGG: function(gname) {
      this.openGroup(gname);
      this.$nextTick(() => { this.gotoGroup(gname) });
    }
  },
  created: function() {
    // Get data
    this.$http.get(this.listUrl)
      .then(response => {
        this.value = response.data;
        this.loaded = true;
        this.$nextTick(() => { this.changed = false; });
      }, response => {
        this.myAlert("Problemi a caricare la risorsa!");
      });
  },
  computed: {
    ...mapGetters(['doesWorkflow', 'updatesCards'])
  }

}
</script>
<style>
.q-tab-panels {
  background-color: #eeefee;
}
.group-class {
  background-color: #eeeefe;
  border: 1px solid #ddddfe;
}
</style>
