/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual
// application logic in a relevant structure within app/javascript and
// only use these pack files to reference
// that code so it'll be compiled.

import {loadStripe} from '@stripe/stripe-js';
import Vue from 'vue';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import ActionCableVue from 'actioncable-vue';
import { Quasar,
         Notify,
         Loading,
         Ripple,
         LoadingBar,
         TouchPan,
         Dialog,
         ClosePopup } from 'quasar/dist/quasar.common.js';
// Usato dai filtri formatDate
import { date } from 'quasar/dist/quasar.common.js';
// import 'quasar-extras/fontawesome-v5';
import 'quasar/dist/quasar.min.css';
import 'quasar-extras/material-icons/material-icons.css';

// Sentry
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import App from 'app';
import GeneralHistoryEdit from 'patients/dentisti/general_history_edit.vue';


import VueQRCodeComponent from 'vue-qrcode-component';
import VCalendar from 'v-calendar';

import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton)

const nomiGiorni = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì',
                    'Giovedì', 'Venerdì', 'Sabato'];
const g_nomiMesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile',
                    'Maggio', 'Giugno', 'Luglio', 'Agosto',
                    'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
window.g_nomiMesi = g_nomiMesi;
Vue.use(VCalendar, {
  locales: {
    'it-IT': {
      firstDayOfWeek: 2,
      masks: {
        L: 'DD-MM-YYYY',
        monthLabels: g_nomiMesi,
        firstDayOfWeek: 2,
        weekdayLabels: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
        // navMonths: g_nomiMesi
        // ...optional `title`, `weekdays`, `navMonths`, etc
      }
    }
  }
});
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(Vuex);

import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

// Should not do.. but this is so annoying otherwise!
Vue.mixin({
  methods: {
    myNotice: function(msg) {
      this.$q.notify({
        message: msg,
        color: 'positive'
      });
    },
    // TODO: If we are passed resp, we should also tell it we already messaged
    myAlert: function(msg, resp) {
      this.$q.notify({
        message: msg + (resp ? "\n" + JSON.stringify(resp.data, undefined, 2) : ''),
        color: 'negative'
      });
    }
  },
  data: function() {
    return {
      scPracticeName: "dentisti"
    };
  }
});

// Vuex Store. Bet you guessed it.
const store = new Vuex.Store({
  state: {
    user: { id: 0 },
    patient: null,
    sites: [],
    showLeftMenu: true,
    footer: '',
    siteAddress: '',
    displayEmpty: false,
    patientDisplay: true
  },
  mutations: {
    setUser(state, user) {
      state.sites = user.sites;
      delete user['sites']
      // console.log("Setting user in sentry: %o", user);
      Sentry.setUser({ username: user.email });
      // console.log("Done");
      state.user = user;
    },
    setPatient(state, patient) {
      // console.log("Setto patient in state a %o", patient);
      state.patient = {...patient};
    },
    clearUser(state) {
      Sentry.setUser({ username: null });
      state.user = {};
    },
    addSite(state, site) {
      state.sites.push(site);
    },
    removeSite(state, site) {
      state.sites.splice(state.sites.findIndex(inner => inner.id === site.id), 1)
    },
    hideLeftMenu(state) {
      state.showLeftMenu = false;
    },
    toggleLeftMenu(state) {
      state.showLeftMenu = !state.showLeftMenu;
    },
    setDisplayEmpty(state, value) {
      state.displayEmpty = value;
    },
    setPatientDisplay(state, value) {
      state.patientDisplay = value;
    },
    setFooter(state, footer) {
      state.footer = footer;
    },
    setSiteAddress(state, address) {
      state.siteAddress = address;
    }
  },
  actions: {
    reloadUserFromBackEnd(context) {
      // console.log("Eseguo action: reload...");
      Vue.http.get('/users/me.json')
        .then(response => {
          // console.log("users/me returned from server with 200, setting stuff in proto: %o",
          //            response.data);
          context.commit('setUser', response.data);
        }, response => {
          console.log("Errore prelevando /users/me - response: %o", response);
        });
    },
    async reloadPatientFromBackEnd({ commit, state }, patientId) {
      // console.log("Eseguo action: reloadPatientFromBackend async con param %o", patientId);
      await Vue.http.get(`/patients/${patientId}.json`)
        .then(response => {
          // console.log("get patient returned from server with 200, setting stuff in proto: %o",
          //            response.data);
          commit('setPatient', response.data);
        }, response => {
          console.log("Errore prelevando patient: response: %o", response);
        });
      // console.log("Esco da reloadPatientfrombackend, dovrebbe aver aspettato...");
    },
    async initializeNewPatient({ commit, state }) {
      await commit('setPatient', {
        name: '',
        surname: '',
        telephone: '',
        birthday: null,
        birthmonth: null,
        birthyear: null,
        sex: null,
        notes: '',
        address: '',
        country: 'Italia',
        email: '',
        occupation: '',
        study_title: '',
        fiscal_code: '',
        recall_date: null,
        sms_consent: null,
        site_id: null,
        site: {},
        sms_consent: false,
        telephone: '+39 '
      });
    }
  },
  getters: {
    practice: state => state.user.practice,
    patientDisplay: state => state.patientDisplay,
    displayEmpty: state => state.displayEmpty,
    practiceName: state => state.user.practice.name,
    practiceExtendedName: state => state.user.practice.extended_name,
    visitDuration: state => state.user.practice.visit_duration,
    siteAddress: state => state.siteAddress,
    hasMultiSite: state => state.sites.length > 1,
    // Permission related; secretaries used not to do patients, now they do
    doesPatients: state => {
      if (!state.user) return false;
      return true;
    },
    doesWorkflow: state => {
      if (!state.user) return false;
      return state.user.practice.workflow;
    },
    updatesCards: state => {
      if (!state.user) return false;
      return state.user.kind === 'admin' ||
        state.user.kind === 'doctor' ||
        state.user.kind === 'secretary';
    },
    isAdmin: state => {
      return state.user.kind === 'admin';
    },
    isDoctor: state => {
      return state.user.kind === 'doctor';
    },
    isSecretary: state => {
      return state.user.kind === 'secretary'
    },
    hires_visus: state => {
      if (!state.user) return false;
      return state.user.hires_visus;
    },
    patient: state => state.patient,
    hasFatturazione: state => {
      return !!(state.user.practice.fatture_token &&
                state.user.practice.fatture_company_id);
    }
  }
})

// TODO: Put recaptcha back
// import { VueReCaptcha } from 'vue-recaptcha-v3'
// Vue.use(VueReCaptcha, { siteKey: '' })

import {
  // QMarkupTable,
  QAvatar,
  QBadge,
  QBanner,
  QBtn,
  QBtnGroup,
  QCard,
  QCardActions,
  QCardMedia,
  QCardSection,
  QCardTitle,
  QCheckbox,
  QChip,
  QColor,
  QDate,
  QDialog,
  QDrawer,
  QExpansionItem,
  QFab,
  QFabAction,
  QField,
  QFile,
  QFooter,
  QForm,
  QHeader,
  QIcon,
  QImg,
  QInput,
  QItem,
  QItemLabel,
  QItemSection,
  QLayout,
  QLinearProgress,
  QList,
  QOptionGroup,
  QPage,
  QPageContainer,
  QPageScroller,
  QPageSticky,
  QPagination,
  QPopupProxy,
  QRadio,
  QRouteTab,
  QSelect,
  QSeparator,
  QSpace,
  QTab,
  QTabPanel,
  QTabPanels,
  QTable,
  QTabs,
  QTd,
  QTh,
  QToggle,
  QToolbar,
  QToolbarTitle,
  QTooltip,
  QTr,
  QTree,
  QUploader,
} from 'quasar/dist/quasar.common.js';

const globalComponents = {
  // QMarkupTable,
  QAvatar,
  QBadge,
  QBanner,
  QBtn,
  QBtnGroup,
  QCard,
  QCardActions,
  QCardMedia,
  QCardSection,
  QCardTitle,
  QCheckbox,
  QChip,
  QColor,
  QDate,
  QDialog,
  QDrawer,
  QExpansionItem,
  QFab,
  QFabAction,
  QField,
  QFile,
  QFooter,
  QForm,
  QHeader,
  QIcon,
  QImg,
  QInput,
  QItem,
  QItemLabel,
  QItemSection,
  QLayout,
  QLinearProgress,
  QList,
  QOptionGroup,
  QPage,
  QPageContainer,
  QPageScroller,
  QPageSticky,
  QPagination,
  QPopupProxy,
  QRadio,
  QRouteTab,
  QSelect,
  QSeparator,
  QSpace,
  QTab,
  QTabPanel,
  QTabPanels,
  QTable,
  QTabs,
  QTd,
  QTh,
  QToggle,
  QToolbar,
  QToolbarTitle,
  QTooltip,
  QTr,
  QTree,
  QUploader,
};

import iconSet from 'quasar/icon-set/material-icons';
import langIt from 'quasar/lang/it'
Vue.use(Quasar, {
  components: globalComponents,
  directives: { Ripple, ClosePopup, TouchPan },
  cssAddon: true,
  lang: langIt,
  iconSet: iconSet,
  plugins: {
    Notify,
    LoadingBar,
    Dialog,
    Loading,
  },
  config: {
    loadingBar: { color: 'brand', size: '6px' },
    loading: { delay: 200 },
  }
});
Vue.use(Vuelidate);

import VueDraggable from 'vue-draggable';

Vue.use(VueDraggable);

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectImmediately: false,
});

Vue.component('qr-code', VueQRCodeComponent);

Vue.filter('formatDate', (value) =>
           value ? date.formatDate(value, 'DD/MM/YYYY') : '');

Vue.filter('translateDate', (value) => {
  return date.formatDate(value, 'dddd DD MMMM YYYY', {
    days: nomiGiorni,
    months: g_nomiMesi
  });
});

Vue.filter('getHour', (value) => {
  return date.formatDate(value, 'HH:mm');
});

Vue.filter('translateTime', (value) =>
           date.formatDate(value, 'dddd DD MMMM HH:mm', {
             days: nomiGiorni,
             months: g_nomiMesi
           }))

Vue.filter('formatDateTime', (value) =>
           value ? date.formatDate(value, 'DD/MM/YYYY HH:mm') : '');

Vue.filter('dueChars', (value) => (value.toString().length != 1) ? value : `0${value}`);

Vue.filter('roundNum', (value) => {
  // console.log("value? %o", value);

  return Number(value).toFixed(2);
});

Vue.http.interceptors.push(function(request) {
  if (!this) {
    // console.warn("*** Nell' interceptor this is null for request %o", request);
    return
  }
  this.$q.loading.show();
  return function(response) {
    // console.log("Interceptor della response: %o", response);
    this.$q.loading.hide();
    if (response.status === 0) {
      this.myAlert('Impossibile contattare il server');
      return;
    } else if (response.status == 401) {
      console.log("status 401 in interceptor");
      if (response.data.subscription === 'trial_expired') {
        this.myAlert('Il tuo abbonamento non è valido.\n' +
                     'Abbonati per continuare ad usare CloudMedical');
      } else if (response.url.indexOf('/fatture/') == 0) {
        this.myAlert('Problema di autenticazione con Fatture ' +
                     'in Cloud, controlla i settaggi');
        return;
      } else {
//        if (response.url === '/users/me.json') {
        // Sloggami
//        console.log("401 sulla /me, slogghiddio");
        // ma route e' / quando dovrebbe essere qualsiasi altra cosa!
        // dio anellide
//        console.log("router? %o", this.$router.currentRoute);
        this.$store.commit('clearUser');
//       if (this.$router.currentRoute.fullPath.indexOf('/app/') >= 0) {
          console.log("route conteneva app, vado a login");
          this.$router.push('/')
  //      }
      }
      return;
    }
    // Error handling generico.
    // Forse potrebbe semplificare molte cb di chiamate?
    // TODO: Half assed; Se ci fosse modo di sapere che non e' stato
    // chiamato un altro messaggiatore sarebbe utile, cosi' rischia
    // di duplicare messaggi. Inoltre nel caso in cui riceviamo una
    // notifica per eliminazione di antro card con attachment, per
    // la quale la 404 e' expected, da fastidio perche' messaggia.
    // Half solved with "beQuiet" on response.. but still.. meh!
    if (!response.ok) {
      if (response.beQuiet) return;
      let message = 'Problemi a caricare la risorsa';
      if (response.data.errors) {
        message += `: ${JSON.stringify(response.data.errors)}`;
      }
      this.myAlert(message);
    }
  }
});

// Il token anti CSRF di rails; Nota che rails non lo fa in test env..
// Quindi ho dovuto controllare la presenza del meta tag
Vue.http.interceptors.push(request => {
  if (request.method === 'POST' ||
      request.method === 'PATCH' ||
      request.method === 'DELETE') {
    var qs = document.querySelector("meta[name='csrf-token']");
    if (qs) {
      // console.log("ho il csrf-token e je lo mando");
      request.headers.set('X-CSRF-Token', qs.getAttribute('content'));
    } else {
      console.log("Non ho il csrf-token, non je lo mando");
    }
  }
});

const routes = [
  // { path: '/', redirect: '/prenotazioni' },
  // { path: '/',
  //   name: 'root',
  //   props: false },
  { path: '/',
    component: () => import('logged_out/logged_out'),
    children: [
      {
        path: '/',
        component: () => import('logged_out/login')
      },
      {
        path: '/register',
        component: () => import('logged_out/registration')
      },
      {
        path: '/accept_invite',
        component: () => import('logged_out/accept_invite')
      },

      {
        path: '/resend_password',
        component: () => import('logged_out/resend_password')
      },
      {
        path: '/resend_email',
        component: () => import('logged_out/resend_email')
      },
      {
        path: '/thank_you',
        component: () => import('logged_out/thank_you')
      },
      {
        path: '/config_fic',
        component: () => import('logged_out/config_fic')
      }
    ]
  },
  { path: '/app',
    component: () => import('logged_in'),
    children: [
      {
        path: 'prenotazioni',
        component: () => import('appointments/appointments'),
        name: 'prenotazioni',
        props: true
      },
      {
        path: 'workflow',
        component: () => import('appointments/workflow'),
        name: 'workflow'
      },
      {
        path: 'patients',
        component: () => import('patients/patients.vue'),
        name: 'pazienti'
      },
      {
        path: 'new_patient',
        component: () => import('patients/new_patient.vue'),
        name: 'new_patient'
      },
      {
        path: 'patient/:patientId',
        component: () => import('patients/patient.vue'),
        name: 'patient',
        props: true,
        children: [
          { path: 'anamnesi',
            component: () => import('patients/anamnesi.vue'),
            props: true,
            name: 'anamnesi',
            children: [{ path: 'anagrafica',
  component: () => import('patients/patient_form'),
  props: true,
  name: 'anagrafica'
},
{
  path: 'generale',
  component: GeneralHistoryEdit,
  props: true,
  name: 'generale'
},
]

          },
          { path: 'valutazione',
            component: () => import('patients/evaluation.vue'),
            props: true,
            name: "valutazione"
          },
          { path: 'anamnesi_completa',
            component: () => import('patients/anamnesi_completa.vue'),
            props: true,
            name: "anamnesi_completa"
          },
          { path: 'fatturazione',
            component: () => import('patients/fatturazione.vue'),
            props: true,
            name: "fatturazione"
          }
        ]
      },
      {
        path: 'settings',
        component: () => import('settings'),
        name: 'settings'
      },
      {
        path: 'subscriptions',
        component: () => import('subscriptions'),
        name: 'subscriptions'
      },
      // TODO put them components in a subfolder
      {
        path: 'stripe_success',
        component: () => import('stripe_success'),
        name: 'stripe_success'
      },
      {
        path: 'stripe_canceled',
        component: () => import('stripe_canceled'),
        name: 'stripe_canceled'
      },
      {
        path: 'change_password',
        component: () => import('change_password')
      },
      // Quelli dell'utente loggato
      { path: 'sites',
        component: () => import('sites'),
        name: 'sites'
      },
      // Elenco prestazioni di fatture in cloud
      { path: 'prodotti_fic',
        component: () => import('prodotti_fic'),
        name: 'prodotti_fic'
      },
      {
        path: 'admin',
        component: () => import('admin'),
        children: [
          { path: '',
            component: () => import('admin/users'),
            name: 'admin',
            alias: 'users'
          },
          {
            path: 'user/:id',
            component: () => import('admin/user_edit'),
            name: 'admin_user_edit',
            props: true
          },
          { path: 'practices',
            component: () => import('admin/practices'),
            name: "admin_practices"
          },
          {
            path: 'practice/:id',
            component: () => import('admin/practice_edit'),
            name: 'admin_practice_edit',
            props: true
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
	routes: routes
});

// console.log("got sentry vars? %o, %o",
//             process.env.SENTRY_DSN,
//             process.env.SENTRY_ENVIRONMENT);

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
  // Nope, too chatty on console!
  // debug: false,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost",
                       "oculisti.cloudmedical.it",
                       "podologi.cloudmedical.it",
                       "oculisti-demo.cloudmedical.it",
                       "dentisti.cloudmedical.it",
                       "dentisti-demo.cloudmedical.it",
                       /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  autoSessionTracking: true,
  // initialScope: scope => {
  //   scope.setTags({ a: "b" });
  //  return scope;
  // },
});

async function loadStripeSync() {
  // Apparently it's a good thing to load stripe anyway, for anti fraud protection;
  window.stripe_pk = 'pk_live_51HhEnpJr8W10FGgZGrIMKznEFNPFIniTINGvHVw8yC10OjSedUBe9jrHzOyDv7Sf3DVH1vIOqnn8pzRp38cR3oHl00gSrecQTd';
  try {
    window.stripe = await loadStripe(window.stripe_pk);
  } catch (error) {
    console.error(error);
    // expected output: ReferenceError: nonExistentFunction is not defined
    // Note - error messages will vary depending on browser
  }
};

loadStripeSync();

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});

// When HTML/DOM elements are ready:
// Cancella il contenuto che dice "accesso effettuato con successo"
document.addEventListener('readystatechange', event => {
  if (event.target.readyState === "interactive") {   //does same as:  ..addEventListener("DOMContentLoaded"..
    document.querySelectorAll('.content .notice').forEach((e) => e.innerHTML = '')
  }
});
